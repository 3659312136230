exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-countydown-page-js": () => import("./../../../src/templates/countydown-page.js" /* webpackChunkName: "component---src-templates-countydown-page-js" */),
  "component---src-templates-currenthighsheriff-page-js": () => import("./../../../src/templates/currenthighsheriff-page.js" /* webpackChunkName: "component---src-templates-currenthighsheriff-page-js" */),
  "component---src-templates-development-page-js": () => import("./../../../src/templates/development-page.js" /* webpackChunkName: "component---src-templates-development-page-js" */),
  "component---src-templates-faqs-page-js": () => import("./../../../src/templates/faqs-page.js" /* webpackChunkName: "component---src-templates-faqs-page-js" */),
  "component---src-templates-generalinformation-page-js": () => import("./../../../src/templates/generalinformation-page.js" /* webpackChunkName: "component---src-templates-generalinformation-page-js" */),
  "component---src-templates-history-page-js": () => import("./../../../src/templates/history-page.js" /* webpackChunkName: "component---src-templates-history-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-pasthighsheriffs-page-js": () => import("./../../../src/templates/pasthighsheriffs-page.js" /* webpackChunkName: "component---src-templates-pasthighsheriffs-page-js" */),
  "component---src-templates-privacypolicy-page-js": () => import("./../../../src/templates/privacypolicy-page.js" /* webpackChunkName: "component---src-templates-privacypolicy-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-termsconditions-page-js": () => import("./../../../src/templates/termsconditions-page.js" /* webpackChunkName: "component---src-templates-termsconditions-page-js" */),
  "component---src-templates-therole-page-js": () => import("./../../../src/templates/therole-page.js" /* webpackChunkName: "component---src-templates-therole-page-js" */)
}

